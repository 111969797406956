import React from "react"

import Layout from "../components/layout"

const PrivacyPage: React.SFC<{}> = () => (
  <Layout>
    <h1>プライバシーポリシー</h1>
    <p>
      当社で配信するアプリにおける個人情報の保護およびその適切な取り扱いについての方針を示したものです。
    </p>

    <h3>アクセス解析について</h3>
    <p>
      当社で配信するアプリには、サービスの利用状況を把握するためにアクセス解析を利用しているものがあります。
      取得する情報、利用目的、第三者への提供等につきましては、各事業者の個人情報の取扱いについての情報をご参照ください。
    </p>
    <p>
      Googleプライバシーポリシー
      <br />
      http://www.google.com/intl/ja/policies/privacy/
    </p>

    <h3>情報収集モジュールについて</h3>
    <p>
      当社で配信するアプリでは、広告配信を目的として以下の広告配信事業者がご利用者の情報を自動取得する場合がございます。
      取得する情報、利用目的、第三者への提供等につきましては、各事業者の個人情報の取扱いについての情報をご参照ください。
    </p>
    <p>
      AdMob
      <br />
      http://www.google.com/intl/ja/policies/technologies/ads/
    </p>
    <p>
      Nend
      <br />
      https://nend.net/privacy/sdkpolicy
    </p>
    <p>
      i-mobile
      <br />
      https://www.i-mobile.co.jp/privacy.html
    </p>
    <p>
      Facebook Audhience Network
      <br />
      https://developers.facebook.com/docs/audience-network/policy?locale=ja_JP
    </p>

    <h3>リンク先について</h3>
    <p>
      当サイト、及び当社で配信するアプリでは他者のウェブサイトへリンクが張られている場合がありますが、
      当社は当該リンク先のウェブサイトの運営および管理には関与しておりません。
      したがって当該リンク先のウェブサイトにおける個人情報の取扱いについて、当社は責任を負いかねますので、あらかじめご了承ください。
    </p>
  </Layout>
)

export default PrivacyPage
